@import '../scss/shared-variables';
@import 'bootstrap-variables';
@import 'core-variables';
@import '../scss/shared';

.text-muted {
  color: $gray-400 !important;
}

.bg-custom {
  background-color: #1b2a4e !important;
}

.sidebar-nav .nav-item.open > .nav-link,
.sidebar-nav .nav-link:hover {
  background-color: #203057;
}

.sidebar-nav .nav-item .nav .nav-link.active {
  background-color: rgba(255, 255, 255, 0.02) !important;
}

.sidebar-nav .nav-item.open .nav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
}

.sidebar-nav .nav-link.active,
.sidebar-nav .nav-item.active > .nav-link {
  background-color: #243661;
}

.btn-primary {
  --bs-btn-color: #2c2851 !important;
  --bs-btn-bg: #fffb00 !important;
  --bs-btn-border-color: #fffb00 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f7f300 !important;
  --bs-btn-hover-border-color: #2c2851 !important;
  --bs-btn-focus-shadow-rgb: 76, 72, 107;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f7f300 !important;
  --bs-btn-active-border-color: #f7f300 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fffb00 !important;
  --bs-btn-disabled-border-color: #fffb00 !important;
}

.btn-primary {
  color: #2c2851 !important;
}

.card.bg-transparent-alt {
  background-color: #eaeaea !important;
  border-color: #dadada !important;
}

.sidebar-nav .nav-link.active,
.sidebar-nav .nav-item.active > .nav-link {
  border-left: 0.25rem solid #fffb00 !important;
}

.sidebar-nav .nav-link.active svg,
.sidebar-nav .nav-link:hover svg,
.sidebar-nav .nav-item.active > .nav-link svg,
.sidebar-nav .nav-item.open > .nav-link svg {
  color: #fffb00 !important;
}

.sidebar .text-primary {
  color: #fffb00 !important;
}

.sidebar-profile .list-group-item-action {
  color: #fffb00 !important;
}
